import React from "react"

import SEO from "../components/seo"

const browser = typeof window !== "undefined" && window
const NotFoundPage = () => {
  return (
    browser && (
      <div className="container" style={{position: "fixed"}}>
        <SEO title="404: Not found" />
        <h1>NOT FOUND</h1>
        <p>The page you requested doesn&#39;t exist...</p>
      </div>
    )
  )
}

export default NotFoundPage